import { configureStore } from '@reduxjs/toolkit'
import sidebarReducer from 'src/components/slices/sideBarSlice'
import langReducer from 'src/components/slices/langSlice'
import labelsReducer from 'src/components/slices/labelsSlice'
import excelReducer from 'src/components/slices/excelSlice'
import consoleReducer from 'src/components/slices/consoleSlice'
import copilotReducer from 'src/components/copilot/copilotSlice'
import pdfReducer from 'src/components/rag/pdfSlice'
import pdfFilesReducer from 'src/components/rag/pdfFilesSlice'

import workSpaceReducer from 'src/views/params/workSpace/workSpaceSlice'
import paramGeneReducer from './views/params/generaux/paramGeneSlice'
import tauxPersoReducer from './views/params/tauxPerso/tauxPersoSlice'
import histoReducer from './views/scEco/histo/histoSlice'
import yieldCurvesReducer from './views/scEco/yieldCurve/yieldCurvesSlice'
import scEcoReducer from './views/scEco/det/scEcoSlice'
import ctzReducer from './views/scEco/rn/ctz/ctzSlice'
import inputFormReducer from 'src/components/slices/inputFormSlice'
import modelPointsReducer from './views/passif/modelPoints/modelPointsSlice'
import rachConjsReducer from './views/passif/rachatsConj/rachConjsSlice'
import rachStrucsReducer from './views/passif/rachatsStruc/rachStrucsSlice'
import echeancesReducer from './views/passif/echeances/echeancesSlice'
import decesReducer from './views/passif/deces/decesSlice'
import txAttendusReducer from './views/passif/txAttendus/txAttendusSlice'
import ppbReducer from './views/passif/ppb/ppbSlice'
import stratPbReducer from './views/stratPb/stratPbSlice'
import otsListReducer from './views/stratPb/otsSlice'

import actifsReducer from './views/actif/actifSlice'
import sensiAssetReducer from './views/actif/sensi/sensiSlice'
import chocAssetReducer from './views/actif/sensi/chocSlice'

import fraisReducer from './views/frais/fraisSlice'
import hypFraisReducer from './views/frais/hypFraisSlice'
import balanceSheetReducer from './views/balanceSheet/balanceSheetSlice'

const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    lang: langReducer,
    labels: labelsReducer,
    excel: excelReducer,
    console: consoleReducer,

    copilot: copilotReducer,
    pdf: pdfReducer,
    pdfFiles: pdfFilesReducer,

    workSpace: workSpaceReducer,
    paramGene: paramGeneReducer,
    tauxPerso: tauxPersoReducer,
    histo: histoReducer,
    yieldCurves: yieldCurvesReducer,
    scEcoList: scEcoReducer,
    ctzList: ctzReducer,
    inputForm: inputFormReducer,
    modelPoints: modelPointsReducer,
    rachConjs: rachConjsReducer,
    rachStrucs: rachStrucsReducer,
    echeances: echeancesReducer,
    deces: decesReducer,
    txAttendus: txAttendusReducer,
    ppb: ppbReducer,
    otsList: otsListReducer,
    stratPbList: stratPbReducer,

    actifs: actifsReducer,
    sensiAssets: sensiAssetReducer,
    chocAssets: chocAssetReducer,

    frais: fraisReducer,
    hypFraisList: hypFraisReducer,
    balanceSheet: balanceSheetReducer,
  },
})
export default store
