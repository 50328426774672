import React from 'react'
//import { devise } from '../actif/const'

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

export const formatNumber = (number) => {
  const opts = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  let res = number
  try {
    res = number.toLocaleString('fr-FR', opts)
  } catch (e) {
    console.log(e)
  }
  return res
}

export const formatPercentage = (number, locale = 'fr-FR', minDigits = 2, maxDigits = 2) => {
  let res = number
  try {
    res = Intl.NumberFormat(locale, {
      style: 'percent',
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits,
    }).format(number)
  } catch (e) {
    console.log(e)
  }

  return res
}

export const percentFormatter = (params) => {
  return formatPercentage(params.value)
}

export const formatRateList = (liste) => {
  let res
  try {
    res = liste.map((e) => formatPercentage(e)).join(', ')
  } catch (e) {
    console.log(e)
  }
  return res
}

export const currencyFormatter = (params) => {
  return formatNumber(params.value) // + ' ' + devise.symbol
}
export const numberFormatter = (params) => {
  return formatNumber(params.value)
}

// 'dd/mm/yyyy' -> 'yyyy-mm-dd'
export const convertFrDate = (date) => {
  if (date === null) return ''
  if (typeof date === 'string' || date instanceof String) return date.split('/').reverse().join('-')
  else return ''
}

// 'yyyy-mm-dd' -> 'dd/mm/yyyy'
/* export const reverseFrDate = (date) => {
  if (date === null) return ''
  if (typeof date === 'string' || date instanceof String) return date.split('-').reverse().join('/')
  else return ''
} */

export const readDate = (dateString) => {
  const [y, m, d] = dateString.split('-')
  return new Date(y, m - 1, d)
}

export const ExcelDateToDate = (date) => {
  //return new Date(Math.round((date - 25569) * 86400 * 1000))
  return new Date(Date.UTC(0, 0, date - 1))
}
export const ExcelDateToJSDate = (date) => {
  const res = ExcelDateToDate(date)
  return res.toISOString().split('T')[0]
}

export const monthDiff = (date1, date2) => {
  const [y1, m1, d1] = date1.split('-')
  const [y2, m2, d2] = date2.split('-')
  return (parseInt(y2) - parseInt(y1)) * 12 - parseInt(m1) + parseInt(m2)
}

export const yearShift = (dateString, shift) => {
  const [y, m, d] = dateString.split('-')
  return [parseInt(y) + shift, m, d].join('-')
}

export const formatDate = (number) => {
  const res = new Date(number)
  return res.toLocaleDateString('fr-FR')
}
export const dateFormatter = (params) => {
  return params.value ? formatDate(params.value) : ''
}

export const fctTxFormatter = (e) => {
  if (e.ref === 'aucun') return 'aucun'
  if (e.a === 0) {
    return `${formatNumber(e.b)}%`
  } else {
    return `${formatNumber(e.a)}% ${e.ref} + ${formatNumber(e.b)}%`
  }
}

export const txServiFormatter = (params) => {
  const res = params.value.map((e) => `${formatNumber(e.a)}% ${e.ref} + ${formatNumber(e.b)}%`)
  return params.value.length === 1 ? res[0] : `max[${res.join(' ; ')}]`
}

// liste des elements (variables) d'une fonction de taux personnalise
export const elsFormatter = (params) => {
  const res = params.value.map(
    (e, i) =>
      'e' +
      (i + 1) +
      ' =' +
      e.op +
      ' (' +
      e.ref +
      (e.spread === 'aucun spread' ? '' : ' + spread ' + e.spread) +
      ')',
  )

  return (
    <ul>
      {res.map((e, i) => (
        <li key={i}>{e}</li>
      ))}
    </ul>
  )
}

export const sumByCriteria = (data, criteria, cible, added_data) => {
  return data.reduce(function (sum, current) {
    return sum + (current[criteria] === cible) * current[added_data]
  }, 0)
}

export const lastDayOfMonth = (data) => {
  let res = new Date(data)
  const year = res.getFullYear()
  const month = res.getMonth() + 1 // in js, january is 0
  const day = new Date(year, month, 0).getDate()
  res = year + '-' + String(month).padStart(2, '0') + '-' + day
  return res
}

export const getDuplicates = (arr) => {
  const findDuplicates = (e) => e.filter((item, index) => arr.indexOf(item) !== index)
  return [...new Set(findDuplicates(arr))]
}

export function get_url_extension(url) {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}

// convert A to 1, Z to 26, AA to 27
export function lettersToNumber(letters) {
  if (letters.trim() === '') {
    return 'non défini'
  } else {
    for (var p = 0, n = 0; p < letters.length; p++) {
      n = letters[p].charCodeAt() - 64 + n * 26
    }
    return n
  }
}

export function numberToLetters(number) {
  let letter = ''
  while (number > 0) {
    let remainder = (number - 1) % 26
    letter = String.fromCharCode(65 + remainder) + letter
    number = Math.floor((number - remainder) / 26)
  }
  return letter
}

export const capitalized = (word) => word.charAt(0).toUpperCase() + word.slice(1)

export const errCount = (erreurs) => {
  // error counting
  let count = 0
  Object.keys(erreurs).forEach(function (key) {
    if (erreurs[key] !== null) count++
  })
  return count
}

export const objFromArrays = (keys, values) => {
  let obj = {}
  keys.forEach((element, index) => {
    obj[element] = values[index]
  })
  return obj
}

export const uniqueList = (liste) =>
  [...new Set(liste.map((e) => JSON.stringify(e)))].map((e) => JSON.parse(e))

export const cloneAndPluck = function (sourceObject, keys) {
  const newObject = {}
  const sourceKeys = Object.keys(sourceObject)
  keys.forEach((key) => {
    if (sourceKeys.includes(key)) {
      newObject[key] = sourceObject[key]
    }
  })
  return newObject
}

export const wordMatch = (target, keywords) => {
  return keywords.reduce(
    (output, word) => (output = output + (target.match(word) !== null ? 1 : 0)),
    0,
  )
}
